import { useMediaQuery } from "@mui/material";
import React from "react";
import Routes from "./config/Routes";
import "./App.css";
import { useLocation } from "react-router-dom";

const App = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const location = useLocation();
  const curruntLink = location.pathname.split("/")[1];

  return (
    <div className="app">
        <Routes matches= {matches} curruntLink = {curruntLink}/>
    </div>
  );
};

export default App;
