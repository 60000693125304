export const restrictionData = {
  shipper: {
    createLoad: true,
    createBidPermission: false,
    loadPermisionForEditDelete: true,
    daPage: false,
    profileUpdatePage: true,
    myLoad: true,
    viewBidPage: true,
    createBidPage: false,
    showBidsTab: false,
    manageTeam: false,
    createAlert: false,
    allLoads: false,
    bidUpdate: false,
    blockUser: false,
    approveBid : false,
  },
  carrier: {
    createLoad: false,
    createBidPermission: true,
    loadPermisionForEditDelete: false,
    daPage: true,
    profileUpdatePage: true,
    myLoad: false,
    viewBidPage: true,
    createBidPage: true,
    showBidsTab: true,
    manageTeam: true,
    createAlert: true,
    allLoads: true,
    bidUpdate: true,
    blockUser: true,
    approveBid : true,
  },
  driver: {
    createLoad: false,
    createBidPermission: false,
    loadPermisionForEditDelete: false,
    daPage: true,
    profileUpdatePage: false,
    myLoad: false,
    viewBidPage: false,
    createBidPage: false,
    showBidsTab: false,
    manageTeam: false,
    createAlert: true,
    allLoads: true,
    bidUpdate: false,
    blockUser: false,
    approveBid : false,
  },
  dispatcher: {
    createLoad: false,
    createBidPermission: true,
    loadPermisionForEditDelete: false,
    daPage: false,
    profileUpdatePage: false,
    myLoad: false,
    viewBidPage: true,
    createBidPage: true,
    showBidsTab: true,
    manageTeam: true,
    createAlert: true,
    allLoads: true,
    bidUpdate: true,
    blockUser: false,
    approveBid : false,
  },
};
