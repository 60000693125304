import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import QuickLinkCard from "../../components/cards/quickLinkCard/QuickLinkCard";
import "./DAPage.css";
import { quickLinkArray } from "../../data/config";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { getDaDetails } from "../../apiActions/userAction";

const DAPage = () => {
  const [daInfo, setDaInfo] = useState({});
  const startGetDaDetails = async () => {
    const daData = await getDaDetails();
    if (daData) {
      setDaInfo(daData);
    }
  };

  useEffect(() => {
    if (Object.keys(daInfo).length >= 0) {
      startGetDaDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ margin: "24px 0px" }}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<LocalShippingIcon className="link-icon" />}
              title="Truck & Equip Tutorials"
              to="tutorials"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<StoreIcon className="link-icon" />}
              title={quickLinkArray.shop}
              link={daInfo.truck_equipment_ship}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<HealthAndSafetyIcon className="link-icon" />}
              title={quickLinkArray.health_insurance}
              link={daInfo.health_insurance}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<PriceChangeIcon className="link-icon" />}
              title={quickLinkArray.financial_planning}
              link={daInfo.financial_planning}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<GroupAddIcon className="link-icon" />}
              title={quickLinkArray.social_media}
              link={daInfo.social_media}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<BusinessCenterIcon className="link-icon" />}
              title={quickLinkArray.business_planning}
              link={daInfo.business_plan}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<LocalGasStationIcon className="link-icon" />}
              title={quickLinkArray.fuel_stops}
              link={daInfo.fuel_stops_pricing}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QuickLinkCard
              icon={<DocumentScannerIcon className="link-icon" />}
              title={quickLinkArray.receipt_doc_scanning}
              link={daInfo.receipt_doc_scanning}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DAPage;
