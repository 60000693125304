import { IconButton, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PaidIcon from "@mui/icons-material/Paid";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import {
  authenticationRestriction,
  checkRoleRestriction,
} from "../../service/restriction";
import {
  getProfileInfo,
  logoutUser,
  stripeSession,
} from "../../apiActions/userAction";
import logoImg from "../../assets/logo.png";

const SidebarComponent = ({ notificationData }) => {
  const [profileInfo, setProfileInfo] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const matches2 = useMediaQuery("(min-width:900px)");

  const getProfileInfoFun = async () => {
    const profileData = await getProfileInfo();
    if (profileData?.reason === "Profile details found") {
      setProfileInfo(profileData?.data);
      // setMenu(false);
    }
  };

  useEffect(() => {
    if (profileInfo && localStorage.getItem("x-auth")) {
      getProfileInfoFun();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!matches2) {
      setOpen(false);
    }
  }, [matches2]);
  const location = useLocation();
  const curruntLink = location.pathname.split("/")[1];

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleMnageTeam = () => {
    // setMenu(false);
    navigate("/manage-team");
    setOpenDropdown(false);
  };

  const handleNavigateBlocked = () => {
    navigate("/blocked");
    setOpenDropdown(false);
  };

  const handleProfilePage = () => {
    setOpenDropdown(false);
    navigate("/profile");
  };

  const startLogoutUser = async (redirect) => {
    await logoutUser();
    localStorage.removeItem("x-auth");
    localStorage.removeItem("role");
    redirect();
  };

  const handleLogout = () => {
    const redirect = () => navigate("/login");
    startLogoutUser(redirect);
    setOpenDropdown(false);
  };

  const handleSubscription = async () => {
    const { data } = await stripeSession("portal");
    window.location.replace(data?.portal_session_url);
  };

  return (
    <div>
      {matches ? (
        <>
          {curruntLink?.includes("register") ||
          curruntLink?.includes("invite-signup") ||
          curruntLink?.includes("request-account-deletion") ? (
            <></>
          ) : (
            <div className={`sidebar`}>
              {openDropdown && (
                <div
                  className="backdrop_custom"
                  onClick={() => setOpenDropdown(false)}
                ></div>
              )}
              {localStorage.getItem("x-auth") ? (
                <div className="top-navbar">
                  <div className="text">
                    <img className="logo-img" src={logoImg} alt="logo" />
                  </div>
                  <div className="icons-sub-sec">
                    <div className="icon-sec" style={{ position: "relative" }}>
                      {/* {localStorage.getItem("x-auth") && ( */}
                      <IconButton
                        className="notification-sec-navbar"
                        onClick={() => navigate("/notification")}
                      >
                        <NotificationsIcon className="notificaiton-icon" />
                        {notificationData?.find(
                          (ele) => ele.is_seen === false
                        ) && (
                          <div className="notification-alert">
                            <div className="green"></div>
                          </div>
                        )}
                      </IconButton>
                      {/* )} */}
                    </div>
                    <div>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenDropdown}
                        color="inherit"
                      >
                        <MenuIcon className="account-icon" />
                      </IconButton>
                      {openDropdown && (
                        <div className="custom-dropdown-menu">
                          {(profileInfo.email || profileInfo.role) && (
                            <div className="custom-dropdown-item">
                              <div className="email">{profileInfo.email}</div>
                            </div>
                          )}
                          {(profileInfo.email || profileInfo.role) && (
                            <div className="custom-dropdown-item">
                              <div className="role">{profileInfo.role}</div>
                            </div>
                          )}
                          {authenticationRestriction() &&
                            localStorage.getItem("role") &&
                            checkRoleRestriction(
                              localStorage.getItem("role"),
                              "manageTeam"
                            ) && (
                              <div
                                className="custom-dropdown-item"
                                onClick={handleMnageTeam}
                              >
                                Manage Team
                              </div>
                            )}
                          {localStorage.getItem("role") &&
                            checkRoleRestriction(
                              localStorage.getItem("role"),
                              "createAlert"
                            ) && (
                              <div
                                className="custom-dropdown-item"
                                onClick={() => {
                                  navigate("/alert");
                                  setOpenDropdown(false);
                                }}
                              >
                                Manage Alerts
                              </div>
                            )}
                          <div
                            className="custom-dropdown-item"
                            onClick={handleNavigateBlocked}
                          >
                            Blocked Users
                          </div>
                          <div
                            className="custom-dropdown-item"
                            onClick={handleProfilePage}
                          >
                            Manage Proifle
                          </div>
                          <div
                            className="custom-dropdown-item"
                            onClick={handleSubscription}
                          >
                            Manage Subscription
                          </div>
                          <div
                            className="custom-dropdown-item"
                            onClick={handleLogout}
                          >
                            Logout
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="without-header">
                  {openDropdown && (
                    <div
                      className="backdrop_custom"
                      onClick={() => setOpenDropdown(false)}
                    ></div>
                  )}{" "}
                  <img className="logo-img" src={logoImg} alt="logo" />
                </div>
              )}
              <div
                className={`sidebar-item ${
                  open && "sidebar-item-without-text"
                } ${curruntLink.includes("load") && "sidebar-active-item"}`}
              >
                <Link to="/load" className="sidebar-link">
                  {" "}
                  <LocalShippingIcon className="sidebar-icon" />{" "}
                  <span className={`text-link ${open && "text-active"}`}>
                    Loads
                  </span>
                </Link>
              </div>
              {localStorage.getItem("x-auth") &&
                localStorage.getItem("role") !== undefined &&
                checkRoleRestriction(
                  localStorage.getItem("role"),
                  "showBidsTab"
                ) && (
                  <div
                    className={`sidebar-item ${
                      open && "sidebar-item-without-text"
                    } ${curruntLink.includes("bid") && "sidebar-active-item"}`}
                  >
                    <Link to="/bid" className="sidebar-link">
                      {" "}
                      <PaidIcon className="sidebar-icon" />{" "}
                      <span className={`text-link ${open && "text-active"}`}>
                        Bids
                      </span>
                    </Link>
                  </div>
                )}
              {localStorage.getItem("x-auth") &&
                localStorage.getItem("role") !== undefined &&
                checkRoleRestriction(
                  localStorage.getItem("role"),
                  "daPage"
                ) && (
                  <div
                    className={`sidebar-item ${
                      open && "sidebar-item-without-text"
                    } ${
                      (curruntLink.includes("da") ||
                        curruntLink.includes("tutorials")) &&
                      "sidebar-active-item"
                    }`}
                  >
                    <Link to="/da" className="sidebar-link">
                      {" "}
                      <BookmarkIcon className="sidebar-icon" />{" "}
                      <span className={`text-link ${open && "text-active"}`}>
                        DA
                      </span>
                    </Link>
                  </div>
                )}

              {localStorage.getItem("x-auth") &&
                localStorage.getItem("role") !== undefined && (
                  <div
                    className={`sidebar-item ${
                      open && "sidebar-item-without-text"
                    } ${
                      curruntLink.includes("messages") && "sidebar-active-item"
                    }`}
                  >
                    <Link to="/messages" className="sidebar-link">
                      {" "}
                      <QuestionAnswerIcon className="sidebar-icon" />{" "}
                      <span className={`text-link ${open && "text-active"}`}>
                        Messages
                      </span>
                    </Link>
                  </div>
                )}
            </div>
          )}
        </>
      ) : (
        <div className="bottom-sec">
          <div className="bottomBar">
            <div
              className={`bottom-item ${
                curruntLink.includes("load")
                  ? "bottom-item-active"
                  : "bottom-item-deactive"
              }`}
            >
              <Link to="/load" className="link-item-main">
                <LocalShippingIcon className="bottom-icon" /> <br />
                <span className="link-item">Loads</span>
              </Link>
            </div>
            {localStorage.getItem("x-auth") &&
              localStorage.getItem("role") !== undefined &&
              checkRoleRestriction(
                localStorage.getItem("role"),
                "showBidsTab"
              ) && (
                <div
                  className={`bottom-item ${
                    curruntLink.includes("bid")
                      ? "bottom-item-active"
                      : "bottom-item-deactive"
                  }`}
                >
                  <Link to="/bid" className="link-item-main">
                    <PaidIcon className="bottom-icon" /> <br />
                    <span className="link-item">Bids</span>
                  </Link>
                </div>
              )}
            {localStorage.getItem("x-auth") &&
              localStorage.getItem("role") !== undefined &&
              checkRoleRestriction(localStorage.getItem("role"), "daPage") && (
                <div
                  className={`bottom-item ${
                    curruntLink.includes("da") ||
                    curruntLink.includes("tutorials")
                      ? "bottom-item-active"
                      : "bottom-item-deactive"
                  }`}
                >
                  <Link to="/da" className="link-item-main">
                    <BookmarkIcon className="bottom-icon" /> <br />
                    <span className="link-item">DA</span>
                  </Link>
                </div>
              )}
            {localStorage.getItem("x-auth") &&
              localStorage.getItem("role") !== undefined && (
                <div
                  className={`bottom-item ${
                    curruntLink.includes("messages")
                      ? "bottom-item-active"
                      : "bottom-item-deactive"
                  }`}
                >
                  <Link to="/messages" className="link-item-main">
                    <QuestionAnswerIcon className="bottom-icon" /> <br />
                    <span className="link-item">Messages</span>
                  </Link>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarComponent;
