export const quickLinkArray = {
  shop: "Shop",
  health_insurance: "Health Insurance",
  financial_planning: "Financial Planning",
  business_planning: "Business Planning",
  social_media: "Social Media",
  fuel_stops: "Fuel Stops",
  receipt_doc_scanning: "Receipt Doc Scanning",
};

export const domain = "localhost:3000/login";


// export const domain =
//   process.env.NODE_ENV === "development"
//     ? "https://my50freight.com/login"
//     : "https://app.50freight.co/login";

export const stripeAPiKeys = {
  publishableKey:
    // "pk_live_51METY5FQNssTsDdULlSo8KgH6St8CU0QVWUWQBThrgTVPyTP5iNqhFLOXBf7SHNLoa8zm4wG9KkMc0RZMnHt8ubC00L2ERxwj8", // live publish key
    process.env.NODE_ENV === "development"
      ? "pk_test_51METY5FQNssTsDdUOAVY5l0M6HIne4ttox59QZRmRHXVpxxiwsYB7gc7dMHMVa8DEtDlNtut5L6qxQE6pRT349QW00FLnYlNeV"
      : "pk_live_51METY5FQNssTsDdULlSo8KgH6St8CU0QVWUWQBThrgTVPyTP5iNqhFLOXBf7SHNLoa8zm4wG9KkMc0RZMnHt8ubC00L2ERxwj8", // test publish key
};

export const allEquipmentList = [
  {
    name: "Dry Van",
    title: "Dry Van",
  },
  {
    name: "Refer",
    title: "Refer",
  },
  {
    name: "Flat Bed",
    title: "Flat Bed",
  },
  {
    name: "Tanker",
    title: "Tanker",
  },
  {
    name: "Dry Bulk",
    title: "Dry Bulk",
  },
  {
    name: "Oversize",
    title: "Oversize",
  },
  {
    name: "Straight Truck",
    title: "Straight Truck",
  },
  {
    name: "Specialized",
    title: "Specialized",
  },
  {
    name: "Moving Van",
    title: "Moving Van",
  },
  {
    name: "Pickup Truck",
    title: "Pickup Truck",
  },
];

export const user = {
  shipper: "shipper",
  carrier: "carrier",
  driver: "driver",
  dispatcher: "dispatcher",
};

export const timeZones = {
  "America/New_York": "EST",
  "America/Chicago": "CST",
  "America/Denver": "MST",
  "America/Los_Angeles": "PST",
};

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://api.my50freight.com/api"
    : "https://api.50freight.co/api";

// TODO
export const GOOGLE_API_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyD3el-4kPYjIGbFhDtqvs8xHpDnLjaLiIA"
    : "AIzaSyD3el-4kPYjIGbFhDtqvs8xHpDnLjaLiIA";
