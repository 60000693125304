import React from "react";
import "./CustomYellowHoverOutlineButton.css";

const CustomYellowHoverOutlineButton = ({
  innerText,
  onClickHandle,
  inverted,
}) => {
  return (
    <div
      className={
        inverted
          ? "button-yellow-to-outline-inverted"
          : "button-yellow-to-outline"
      }
      onClick={onClickHandle}
    >
      {innerText}
    </div>
  );
};

export default CustomYellowHoverOutlineButton;
