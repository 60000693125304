import React from "react";
import { useNavigate } from "react-router-dom/dist";
import CustomYellowHoverOutlineButton from "../../customComponents/customYellowHoverOutlineButton/CustomYellowHoverOutlineButton";
import "./QuickLinkCard.css";

const QuickLinkCard = (props) => {
  const { title, icon, link, to } = props;
  const navigate = useNavigate();
  return (
    <div className="quick-link-card">
      <div className="card-content">
        <div className="icon-section">{icon}</div>
        <div className="card-footer">
          {/* <h3 className="card-title">{title}</h3> */}
          <div className="card-button">
            <CustomYellowHoverOutlineButton
              innerText={title}
              inverted={true}
              onClickHandle={() =>
                to ? navigate(`/${to}`) : window.open(link, "_blank")
              }
            />
          </div>
        </div>
      </div>
      {/* <div
      className="quick-link-card"
      onClick={() =>
        props.to ? navigate(`/${props.to}`) : window.open(props.link, "_blank")
      }
    >
      <div className="inner-card">
        <div className="icon">{props.icon}</div>
        <div className="title">{props.title}</div>
      </div>
    </div> */}
    </div>
  );
};

export default QuickLinkCard;
