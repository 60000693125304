import { domain, timeZones } from "../data/config";
import { restrictionData } from "./RestrictionData";
import moment from "moment-timezone";

export const checkRoleRestriction = (role, feature) => {
  if (role !== "undefined") {
    if (role && restrictionData[role][feature]) {
      return true;
    } else {
      return false;
    }
  }
};

export const authenticationRestriction = () => {
  if (localStorage.getItem("x-auth") && localStorage.getItem("role")) {
    return true;
  } else {
    return false;
  }
};

export const handleRedirectToLoginPage = () => {
  // alert("see")
  localStorage.removeItem("role");
  localStorage.removeItem("x-auth");
  return (window.location.href = domain);
};

export const convertToUtcCustom = (date) => {
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return new Date(now_utc).toISOString().slice(0, 16);
};

export const convertToSpecificTimeZone = (date, timezone) => {
  return moment(date).tz(timezone)?.format();
};

export const convertToViewTimeZone = (date, timezone) => {
  if (timeZones[timezone] && date) {
    return (
      <span>
        {moment(date).tz(timezone)?.format("MM-DD-YYYY, HH:mm")} &nbsp;
        {timeZones[timezone]}
      </span>
    );
  } else {
    return (
      <span>
        {moment(date).tz("America/New_York")?.format("MM-DD-YYYY, HH:mm")}{" "}
    &nbsp;
        EST
      </span>
    );
  }
};

// if(response.status === 401){
//   handleRedirectToLoginPage()
// }
