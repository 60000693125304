import swal from "sweetalert";
import axios from "../config/axios";
import { handleRedirectToLoginPage } from "../service/restriction";

export const startGetSingleLoad = async (id) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get(
        `loads?load_id=${id}&expand_bids=${true}`,
        {
          headers: {
            authorization: `Token ${localStorage.getItem("x-auth")}`,
          },
        }
      );
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};
export const getAllBids = async (id) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get(`bids?load_id=${id}`, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const startGetnotification = async (id) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("notifications", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const startUpdateNotification = async (ids) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put(
        "notifications",
        { notification_id: ids },
        {
          headers: {
            authorization: `Token ${localStorage.getItem("x-auth")}`,
          },
        }
      );
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const startGetAllLoadsWithFilterAndPagination = async (formData) => {
  const {
    min_price,
    max_price,
    min_weight,
    max_weight,
    origin_lat,
    origin_lng,
    destination_lat,
    destination_lng,
    radius,
    page_number,
    pickup_date_start,
    pickup_date_end,
    delivery_data_start,
    delivery_data_end,
    sort_by,
    expand_bids,
    status,
  } = formData;

  let url = `loads?`;
  if (min_price) {
    url = url.concat(`min_price=${min_price}&`);
  }
  if (max_price) {
    url = url.concat(`max_price=${max_price}&`);
  }
  if (min_weight) {
    url = url.concat(`min_weight=${min_weight}&`);
  }
  if (max_weight) {
    url = url.concat(`max_weight=${max_weight}&`);
  }
  if (origin_lat) {
    url = url.concat(`origin_lat=${origin_lat}&`);
  }
  if (origin_lng) {
    url = url.concat(`origin_lng=${origin_lng}&`);
  }
  if (destination_lat) {
    url = url.concat(`destination_lat=${destination_lat}&`);
  }
  if (destination_lng) {
    url = url.concat(`destination_lng=${destination_lng}&`);
  }
  if (radius) {
    url = url.concat(`radius=${radius}&`);
  }
  if (pickup_date_start) {
    url = url.concat(`pickup_date_start=${pickup_date_start}&`);
  }
  if (page_number) {
    url = url.concat(`page_number=${page_number}&`);
  }
  if (pickup_date_end) {
    url = url.concat(`pickup_date_end=${pickup_date_end}&`);
  }
  if (delivery_data_start) {
    url = url.concat(`delivery_data_start=${delivery_data_start}&`);
  }
  if (delivery_data_end) {
    url = url.concat(`delivery_data_end=${delivery_data_end}&`);
  }
  if (sort_by) {
    url = url.concat(`sort_by=${sort_by}&`);
  }
  if (expand_bids) {
    url = url.concat(`expand_bids=${expand_bids}&`);
  }
  if (status) {
    url = url.concat(`status=${status}&`);
  }
  url = url.slice(0, url.length - 1);

  try {
    const getLoads = await axios.get(encodeURI(url), {
      headers: {
        authorization: `Token ${localStorage.getItem("x-auth")}`,
      },
    });
    return getLoads?.data;
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const startGetAllLoadsWithFilterAndPaginationPublicLoads = async (
  formData
) => {
  const {
    min_price,
    max_price,
    min_weight,
    max_weight,
    origin_lat,
    origin_lng,
    destination_lat,
    destination_lng,
    radius,
    page_number,
    pickup_date_start,
    pickup_date_end,
    delivery_data_start,
    delivery_data_end,
    sort_by,
    expand_bids,
  } = formData;

  let url = `public-loads?`;
  if (min_price) {
    url = url.concat(`min_price=${min_price}&`);
  }
  if (max_price) {
    url = url.concat(`max_price=${max_price}&`);
  }
  if (min_weight) {
    url = url.concat(`min_weight=${min_weight}&`);
  }
  if (max_weight) {
    url = url.concat(`max_weight=${max_weight}&`);
  }
  if (origin_lat) {
    url = url.concat(`origin_lat=${origin_lat}&`);
  }
  if (origin_lng) {
    url = url.concat(`origin_lng=${origin_lng}&`);
  }
  if (destination_lat) {
    url = url.concat(`destination_lat=${destination_lat}&`);
  }
  if (destination_lng) {
    url = url.concat(`destination_lng=${destination_lng}&`);
  }
  if (radius) {
    url = url.concat(`radius=${radius}&`);
  }
  if (pickup_date_start) {
    url = url.concat(`pickup_date_start=${pickup_date_start}&`);
  }
  if (page_number) {
    url = url.concat(`page_number=${page_number}&`);
  }
  if (pickup_date_end) {
    url = url.concat(`pickup_date_end=${pickup_date_end}&`);
  }
  if (delivery_data_start) {
    url = url.concat(`delivery_data_start=${delivery_data_start}&`);
  }
  if (delivery_data_end) {
    url = url.concat(`delivery_data_end=${delivery_data_end}&`);
  }
  if (sort_by) {
    url = url.concat(`sort_by=${sort_by}&`);
  }
  if (expand_bids) {
    url = url.concat(`expand_bids=${expand_bids}&`);
  }
  url = url.slice(0, url.length - 1);

  try {
    const getLoads = await axios.get(encodeURI(url));
    return getLoads?.data;
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const startGetAllAlerts = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("alert", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const createAlert = async (alertData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post("alert", alertData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const deleteAlert = async (id) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.delete(`alert?alert_id=${id}`, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const deleteLoad = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put(`loads`, formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getProfileInfo = async () => {
  if (localStorage.getItem("role")) {
    try {
      if (localStorage.getItem("x-auth")) {
        const response = await axios.get("profile", {
          headers: {
            authorization: `Token ${localStorage.getItem("x-auth")}`,
          },
        });
        if (response?.data?.reason === "Profile details found") {
          localStorage.setItem("role", response?.data?.data?.role);
        }
        return response.data;
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        handleRedirectToLoginPage();
      }
    }
  }
};

export const getAllBidsForShipper = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("bids", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getDaDetails = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("da", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getUserInvites = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("invite", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getUserTeam = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("team", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getUpdateProfile = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put("profile", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const changePassword = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post("change-password", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });

      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const createLoad = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post("loads", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const forgotPAssword = async (formData) => {
  try {
    const response = await axios.post("forgot-password", formData);
    if (response?.data?.reason === "Password changed successfully") {
      return response.data;
    } else {
      return swal({
        icon: "error",
        title: "Oops...",
        text: response.data?.reason,
      });
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const createUserInvite = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post("invite", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const createInviteSignup = async (formData) => {
  try {
    const response = await axios.post("signup", formData);
    return response.data;
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const sendVerificationEmail = async (email) => {
  try {
    const response = await axios.get(`email-verification?email=${email}`);
    if (response?.data?.code !== 200) {
      swal({
        icon: "error",
        title: "Oops...",
        text: response.data?.reason,
      });
    } else {
      swal({
        icon: "success",
        title: "Successfull!!",
        text: response.data?.reason,
      });
      return response.data;
    }
  } catch (err) {
    return swal({
      icon: "error",
      title: "Oops...",
      text: err?.response?.data?.reason
        ? err?.response?.data?.reason
        : "Something went wrong!!",
    });
  }
};

export const getLoginUser = async (formData) => {
  try {
    const response = await axios.post("login", formData);
    if (response?.data?.code !== 200) {
      swal({
        icon: "error",
        title: "Oops...",
        text: response.data?.reason,
      });
    }
    if (
      response?.data?.reason === "Email not verified, please verify your email."
    ) {
      return swal({
        icon: "error",
        title: "Oops...",
        text: response.data?.reason,
        buttons: ["Cancel", "Resend Email"],
      }).then(
        async (value) => value && (await sendVerificationEmail(formData?.email))
      );
    }
    return response.data;
  } catch (err) {
    return swal({
      icon: "error",
      title: "Oops...",
      text: err?.response?.data?.reason
        ? err?.response?.data?.reason
        : "Something went wrong!!",
    });
  }
};

export const logoutUser = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post(
        "logout",
        {},
        {
          headers: {
            authorization: `Token ${localStorage.getItem("x-auth")}`,
          },
        }
      );
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getProfileUpdate = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put("profile", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      const getProfile = await axios.get("profile", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      if (getProfile?.data?.reason === "Profile details found") {
        localStorage.setItem("role", getProfile?.data?.data?.role);
      }
      if (getProfile) {
        return response.data;
      }
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getRegisterUser = async (formData) => {
  try {
    const response = await axios.post("signup", formData);
    if (response?.data?.code !== 200) {
      swal({
        icon: "error",
        title: "Oops...",
        text: response.data?.reason,
      });
    }
    if (
      response?.data?.reason ===
      "Registration was successful, please verify your email to login"
    ) {
      swal({
        icon: "success",
        title: "Success!!",
        text: response.data?.reason,
      });
    }
    return response.data;
  } catch (err) {
    return swal({
      icon: "error",
      title: "Oops...",
      text: err?.response?.data?.reason
        ? err?.response?.data?.reason
        : "Something went wrong!!",
    });
  }
};

export const negotiateBid = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post("bids", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const updateBid = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put("bids", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const acceptRejectBid = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put("bids", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const closeLoad = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.put("loads", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const blockUser = async (formData) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.post("block", formData, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const blockUserUpdate = async (id) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.delete(`block?block_id=${id}`, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const deleteTeamMemeber = async (id) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.delete(`team?user_id=${id}`, {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const getBlockedUser = async () => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get("block", {
        headers: {
          authorization: `Token ${localStorage.getItem("x-auth")}`,
        },
      });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};

export const startGetPaymentIndent =async (bid) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get(
        `payment-intent?bid_id=${bid}`,
        {
          headers: {
            authorization: `Token ${localStorage.getItem("x-auth")}`,
          },
        }
      );
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
}

export const stripeSession = async (session) => {
  try {
    if (localStorage.getItem("x-auth")) {
      const response = await axios.get(
        `stripe-session?stripe_session=${session}`,
        {
          headers: {
            authorization: `Token ${localStorage.getItem("x-auth")}`,
          },
        }
      );
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      handleRedirectToLoginPage();
    }
  }
};
