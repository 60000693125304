import React from "react";
import logo from "../../../assets/50-freight-logo.webp";
import "./RegisterNavbarComponent.css"
import { Container } from "@mui/material";

const RegisterNavbarComponent = () => {
  return (
    <div className="navbar_component">
     <Container>
    <div className="navbar_inner">
    <div className="logo_section">
        <div className="logo_img">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="menu_component">
        <div className="all_link_section">
          <div className="link_sec">
            <a href="#home">About Us </a>
          </div>
          <div className="link_sec">
            <a href="#home">Drivers</a>
          </div>
          <div className="link_sec">
            <a href="#home">Shippers</a>
          </div>
          <div className="link_sec">
            <a href="#home">Benifits</a>
          </div>
          <div className="link_sec">
            <a href="#home">Contact Us</a>
          </div>
        </div>
      </div>
      <div className="cta_component" >
        <button className="cta_button" >Sign Up Today</button>
      </div>
    </div>
     </Container>
    </div>
  );
};

export default RegisterNavbarComponent;
