import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DraftsIcon from "@mui/icons-material/Drafts";
import "./FooterSection.css";
import { Container } from "@mui/material";

const FooterSection = () => {
  return (
    <div className="footer_section">
      <Container>
        <div className="footer_inner">
          <div className="left_section">
            <div className="icon_sec">
              <WhatsAppIcon />
            </div>
            <div className="icon_text">
              <b>Call</b> (480) 908-9710
            </div>
          </div>

          <div className="middle_section">
            © 2023 50 Freight LLC. <br /> All rights reserved.
          </div>

          <div className="right_section left_section">
            <div className="icon_sec">
              <DraftsIcon />
            </div>
            <div className="icon_text">
              <b>Email:</b> info@mail.50freight.co
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FooterSection;
