import React from "react";
import "./AccountDeletionPage.css";
import { Container } from "@mui/material";

const AccountDeletionPage = () => {
  return (
    <div className="account-deletion-page">
      <Container>
        <h2>Request Account Deletion</h2>
        <p>
          Please send an email to <a href="my50freight@gmail.com" >my50freight@gmail.com</a> to request the deletion
          of all your user data collected by{" "}
          <a href="https://app.50freight.co">https://app.50freight.co</a>. Your
          data will be deleted in 30 days after receiving the email request.
        </p>
        <p>
          We are collecting your email, password, name, address, company name,
          etc as part of your profile data. We also store details about your
          loads and bids as submitted by you during the usage of our app. We
          also store payment details through our payment processor (
          <a href="https://stripe.com">https://stripe.com</a>)
        </p>
      </Container>
    </div>
  );
};

export default AccountDeletionPage;
