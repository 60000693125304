import React, { lazy, Suspense, useEffect, useState } from "react";
import DAPage from "../pages/DAPage/DAPage";
import { FallingLines } from "react-loader-spinner";
import { Routes, Route, Navigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { checkRoleRestriction } from "../service/restriction";
import {
  getProfileInfo,
  startGetnotification,
  startUpdateNotification,
} from "../apiActions/userAction";
import TearmsPage from "../pages/termsPage/TearmsPage";
import SidebarComponent from "../components/sidebarComponent/SidebarComponent";
import RegisterNavbarComponent from "../components/RegisterPageComponents/RegisterNavbarComponent/RegisterNavbarComponent";
import FooterSection from "../components/RegisterPageComponents/FooterSection/FooterSection";
import AccountDeletionPage from "../pages/AccountDeletionPage/AccountDeletionPage";
// import PrivacyPage from "../pages/privacyPage/PrivacyPage";
const BlockedUserPage = lazy(() =>
  import("../pages/bloackedUserPage/BlockedUserPage")
);
const NotificationPage = lazy(() =>
  import("../pages/notificationPage/NotificationPage")
);
const CreateAlertPage = lazy(() =>
  import("../pages/alertPage/CreateAlertPage")
);
const LoginPage = lazy(() => import("../pages/loginPage/LoginPage"));
const RegisterPage = lazy(() => import("../pages/registerPage/RegisterPage"));
const ForgotPasswordPage = lazy(() =>
  import("../pages/forgotPasswordPage/ForgotPasswordPage")
);
const ViewLoadPage = lazy(() => import("../pages/viewLoadPage/ViewLoadPage"));
const EmailVerificationPage = lazy(() =>
  import("../pages/emailVerificationPage/EmailVerificationPage")
);
const ProfileUpdatePage = lazy(() =>
  import("../pages/profileUpdatePage/ProfileUpdatePage")
);
const InviteSignupPage = lazy(() =>
  import("../pages/inviteSignupPage/InviteSignupPage")
);

const NavbarComponent = lazy(() =>
  import("../components/navbarComponent/NavbarComponent")
);
const FooterComponent = lazy(() =>
  import("../components/footerComponent/FooterComponent")
);

const PostPage = lazy(() => import("../pages/postPage/PostPage"));
const MessagesPage = lazy(() => import("../pages/messagesPage/MessagesPage"));
const AllBidsPage = lazy(() => import("../pages/allBidsPage/AllBidsPage"));
const VideoCollectionPage = lazy(() =>
  import("../pages/videoCollectionPage/VideoCollectionPage")
);
const ProfilePage = lazy(() => import("../pages/profilePage/ProfilePage"));
const ManageTeamPage = lazy(() =>
  import("../pages/manageTeamPage/ManageTeamPage")
);
const ChangePasswordPage = lazy(() =>
  import("../pages/changePasswordPage/ChangePasswordPage")
);
const ViewBidPage = lazy(() => import("../pages/viewBidPage/ViewBidPage"));

const ProtectedRoutes = (props) => {
  return (
    <>
      {localStorage.getItem("x-auth") ? (
        <>{props.children}</>
      ) : (
        <>
          {localStorage.getItem("x-auth") === "carrier" ? (
            <Navigate to="/login" />
          ) : (
            <>{props.children}</>
          )}
        </>
      )}
    </>
  );
};

const CheckProfileUpdate = (props) => {
  const [profile, setProfile] = useState({});
  const getProfileInfoFn = async () => {
    const data = await getProfileInfo();
    setProfile(data?.data);
  };

  useEffect(() => {
    if (!profile.role) {
      getProfileInfoFn();
    }
  }, [profile.role]);

  return (
    <>
      {profile?.role === "carrier" && profile?.mcdot === null ? (
        <Navigate to="/profile-update" />
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

const routesView = (props) => {
  const { matches, curruntLink } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [notificationData, setNotificationData] = useState([]);
  const getNotification = async () => {
    const data = await startGetnotification();
    setNotificationData(data?.notifications);
  };

  // const getProfileInfoFn = async () => {
  //   const data = await getProfileInfo();
  // };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getNotification();
    // getProfileInfoFn();
  }, []);

  const handleMarkAsRead = async (id) => {
    await startUpdateNotification([id]);
    getNotification();
  };

  const handleMarkAllRead = async () => {
    const ids = notificationData.map((ele) => ele.id);
    await startUpdateNotification(ids);
    getNotification();
  };

  const routes = [
    {
      path: "/",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <Navigate to="/load" />,
    },
    {
      path: "/register",
      exact: true,
      navbar: <RegisterNavbarComponent {...props} />,
      footer: <FooterSection {...props} />,
      main: () => (
        <>
          {localStorage.getItem("x-auth") ? (
            <Navigate to="/load" />
          ) : (
            <RegisterPage {...props} />
          )}
        </>
      ),
    },
    {
      path: "/login",
      exact: true,
      navbar: <></>,
      footer: <></>,
      main: () => (
        <>
          {localStorage.getItem("x-auth") ? (
            <Navigate to="/load" />
          ) : (
            <LoginPage {...props} />
          )}
        </>
      ),
    },
    {
      path: "/forgot-password",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <ForgotPasswordPage {...props} />,
    },
    {
      path: "/change-password",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <ChangePasswordPage {...props} />,
    },
    {
      path: "/load",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <>
          {localStorage.getItem("x-auth") ? (
            <CheckProfileUpdate>
              <PostPage {...props} />
            </CheckProfileUpdate>
          ) : (
            <PostPage {...props} />
          )}
        </>
      ),
    },
    {
      path: "/terms-and-privacy",
      exact: true,
      main: () => <TearmsPage {...props} />,
    },
    // {
    //   path: "/privacy",
    //   exact: true,
    //   navbar: <NavbarComponent {...props} />,
    //   footer: <FooterComponent {...props} />,
    //   main: () => (
    //     <ProtectedRoutes>
    //       <PrivacyPage {...props} />
    //     </ProtectedRoutes>
    //   ),
    // },
    {
      path: "/alert",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <CheckProfileUpdate>
          <CreateAlertPage {...props} />
        </CheckProfileUpdate>
      ),
    },
    {
      path: "/da",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <>
          <DAPage {...props} />
        </>
      ),
    },
    {
      path: "/messages",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <ProtectedRoutes>
          <CheckProfileUpdate>
            <MessagesPage {...props} />
          </CheckProfileUpdate>
        </ProtectedRoutes>
      ),
    },
    {
      path: "/blocked",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <ProtectedRoutes>
          <CheckProfileUpdate>
            {" "}
            <BlockedUserPage {...props} />
          </CheckProfileUpdate>
        </ProtectedRoutes>
      ),
    },
    {
      path: "/messages/:quickId",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <ProtectedRoutes>
          <CheckProfileUpdate>
            {" "}
            <MessagesPage {...props} />
          </CheckProfileUpdate>
        </ProtectedRoutes>
      ),
    },
    {
      path: "/bid",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <>
          {localStorage.getItem("role") &&
          checkRoleRestriction(
            localStorage.getItem("role"),
            "createBidPage"
          ) ? (
            <AllBidsPage {...props} />
          ) : (
            <Navigate to="/load" />
          )}
        </>
      ),
    },
    {
      path: "/load/:loadId",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <CheckProfileUpdate>
          {" "}
          <ViewLoadPage {...props} handleBackToPage="/load" />
        </CheckProfileUpdate>
      ),
    },
    {
      path: "/bid/:loadId",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <>
          {localStorage.getItem("role") &&
          checkRoleRestriction(
            localStorage.getItem("role"),
            "createBidPage"
          ) ? (
            <ViewLoadPage {...props} handleBackToPage="/bid" />
          ) : (
            <Navigate to="/load" />
          )}
        </>
      ),
    },
    {
      path: "/view-bid/:loadId",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <>
          {localStorage.getItem("role") &&
          checkRoleRestriction(localStorage.getItem("role"), "viewBidPage") ? (
            <ViewBidPage {...props} />
          ) : (
            <Navigate to="/load" />
          )}
        </>
      ),
    },
    {
      path: "/tutorials",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <VideoCollectionPage {...props} />,
    },
    {
      path: "/profile",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <ProtectedRoutes>
          <CheckProfileUpdate>
            {" "}
            <ProfilePage {...props} />
          </CheckProfileUpdate>
        </ProtectedRoutes>
      ),
    },
    {
      path: "/profile-update",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <>
          {localStorage.getItem("x-auth") ? (
            // {localStorage.getItem("x-auth") ? (
              <ProfileUpdatePage {...props} />
          ) : (
            <Navigate to="/load" />
          )}
        </>
      ),
    },
    {
      path: "/email-verification/:token",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <EmailVerificationPage {...props} />,
    },
    {
      path: "/manage-team",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <ProtectedRoutes>
          <CheckProfileUpdate>
            {" "}
            <ManageTeamPage {...props} />
          </CheckProfileUpdate>
        </ProtectedRoutes>
      ),
    },
    {
      path: "/invite-signup",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <InviteSignupPage {...props} />,
    },
    {
      path: "/request-account-deletion",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => <AccountDeletionPage {...props} />,
    },
    {
      path: "/notification",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <NotificationPage
          {...props}
          notificationData={notificationData}
          handleMarkAsRead={handleMarkAsRead}
          handleMarkAllRead={handleMarkAllRead}
        />
      ),
    },
    {
      path: "*",
      exact: true,
      navbar: <NavbarComponent {...props} />,
      footer: <FooterComponent {...props} />,
      main: () => (
        <ProtectedRoutes>
          <Navigate to="/login" />
        </ProtectedRoutes>
      ),
    },
  ];

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100vh",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FallingLines
            color="#4fa94d"
            width="120"
            height="120"
            visible={true}
            ariaLabel="falling-lines-loading"
          />
        </div>
      }
    >
      <NavbarComponent
        notificationData={notificationData}
        {...props}
      ></NavbarComponent>
      <div
        className={`${
          curruntLink.includes("register") ||
          curruntLink?.includes("invite-signup") ||
          curruntLink?.includes("request-account-deletion")
            ? ""
            : `other-page ${!matches && "other-page-close"} ${
                curruntLink.includes("terms-and-privacy") && "display-none"
              }`
        }`}
      >
        <Routes>
          {routes.map((curruntRoute) => (
            <React.Fragment key={uuidv4()}>
              <Route
                key={uuidv4()}
                exact={curruntRoute.exact}
                path={curruntRoute.path}
                element={<curruntRoute.main {...props} />}
                // children={(props) => <curruntRoute.main {...props} />}
              />
            </React.Fragment>
          ))}
        </Routes>
      </div>
      {!curruntLink.includes("terms-and-privacy") && (
        <SidebarComponent notificationData={notificationData} />
      )}
      {/* <FooterComponent></FooterComponent> */}
    </Suspense>
  );
};

export default routesView;
