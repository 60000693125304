import Axios from "axios";
import { baseUrl } from "../data/config";

const axios = Axios.create({
  // baseURL: "http://44.225.165.44:8000/api/",
  // baseURL: "https://f42b-60-243-163-146.in.ngrok.io/api/",
  // baseURL : "https://api.my50freight.com/api",
  baseURL: baseUrl,
});

export default axios;
